import React, { useEffect, useState, useMemo } from "react";
import { useFrame } from "@react-three/fiber";
import {
  useGLTF, useTexture
} from "@react-three/drei";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";

import createAnimation from "./converter";
import blinkData from "./blendDataBlink.json";

import * as THREE from "three";
import axios from "axios";

const _ = require("lodash");
let animations = {};

function Avatar({
  backendAPI,
  textToVoiceAPI,
  avatar_url,
  backendData,
  setAudioSource,
  playing,
  queueInterval,
  speakingInterval,
  setSpeakingInterval,
  responseArray,
  startSpeak,
  setStartSpeak,
  waitForAzure,
  setWaitForAzure,
  waitResponse,
  setWaitResponse,
  emotion,
  waitingSpeeches
}) {
  let gltf = useGLTF(avatar_url);
  let morphTargetDictionary = {};

  let texturesArray = [],
      colorsArray = [],
      textureNamesArray = [],
      colorNamesArray = [];

  for (let i = 0; i < backendData.textures.length; i++) {
    if (backendData.textures[i].texture_image) {
      textureNamesArray.push(backendData.textures[i].object_name);
      texturesArray.push(backendAPI + backendData.textures[i].texture_image);
    }
    else {
      colorNamesArray.push(backendData.textures[i].object_name);
      colorsArray.push(backendData.textures[i].texture_color);
    }
    
  }

  const threeTextures = useTexture(texturesArray);

  _.each(threeTextures, (t) => {
    t.encoding = THREE.sRGBEncoding;
    t.flipY = false;
  });

  gltf.scene.traverse((node) => {
    if (
      node.type === "Mesh" ||
      node.type === "LineSegments" ||
      node.type === "SkinnedMesh"
    ) {
      // console.log(node.name);

      node.castShadow = true;
      node.receiveShadow = true;

      if (node.morphTargetDictionary) {
        morphTargetDictionary[node.name] = node.morphTargetDictionary;
      }
    }
  });

  useEffect(() => {
    gltf.scene.traverse((node) => {
      if (node.type === "Mesh") {
        let styles = [];
        // Find matching Textures
        textureNamesArray.forEach((val, index) => {
          if (
            node.name
              .toLowerCase()
              .includes(textureNamesArray[index].toLowerCase())
          )
          styles.push(threeTextures[index]);
        });

        // Find matching colors
        colorNamesArray.forEach((val, index) => {
          if (
            node.name
              .toLowerCase()
              .includes(colorNamesArray[index].toLowerCase())
          )
          styles.push(colorsArray[index]);
        });

        if (styles.length > 0) {
          const texture = styles.sample();
          console.log(texture)
          if (typeof texture === 'string') {
            node.material.color.setHex(texture);
          } else {
            node.material = new THREE.MeshStandardMaterial();
            node.material.map = texture;
          }
        }
      }
    });
  }, []);

  const [clips, setClips] = useState([]);
  const mixer = useMemo(() => new THREE.AnimationMixer(gltf.scene), []);

  useEffect(() => {
    if (!startSpeak && !waitForAzure) return;

    if (waitForAzure) {
      setClips([]);
      setWaitForAzure(false);
    }

    if (speakingInterval >= queueInterval) {
      setStartSpeak(false);
      return;
    }
    if (queueInterval <= 0) return;

    if (!responseArray[speakingInterval]) {
      const intervalId = setTimeout(() => {
        setWaitForAzure(true);
        setStartSpeak(!startSpeak);
      }, 1000);
      return () => clearTimeout(intervalId);
    }

    let { blendData, filename } = responseArray[speakingInterval];

    // inja timer bezzarim bebinim in tike cheghad zaman mibare
    // ke masale max kardano kamtar konim

    let newClips = [];

    for (const [key, value] of Object.entries(morphTargetDictionary)) {
      newClips.push(createAnimation(blendData, value, key, emotion));
    }

    filename = textToVoiceAPI + filename;

    setClips(newClips);
    setAudioSource(filename);
    setSpeakingInterval(speakingInterval + 1);

    setStartSpeak(false);
  }, [startSpeak]);

  useEffect(() => {
    for (const [key, value] of Object.entries(morphTargetDictionary)) {
      let blink = createAnimation(blinkData, value, key);
      let blinkAction = mixer.clipAction(blink);
      blinkAction.play();
    }
  }, []);

  useEffect(() => {
    if (!waitResponse) return;
    if (waitingSpeeches.length === 0) return;

    const random_number = Math.floor(Math.random() * waitingSpeeches.length);

    const waitData = waitingSpeeches[random_number].blendData;
    const filename = `${textToVoiceAPI}${waitingSpeeches[random_number].filename}`;

    let newClips = [];

    for (const [key, value] of Object.entries(morphTargetDictionary)) {
      newClips.push(createAnimation(waitData, value, key, 0));
    }

    setClips(newClips);
    setAudioSource(filename);
    setWaitResponse(false);
  }, [waitResponse]);

  // Play animation clips when available
  useEffect(() => {
    if (playing === false) {
      _.each(animations, (animation) => {
        animation.stop();
      });
    } else {
      _.each(clips, (clip) => {
        animations[clip.uuid] = mixer.clipAction(clip);
        animations[clip.uuid].setLoop(THREE.LoopOnce);
        animations[clip.uuid].play();
      });
    }
  }, [playing]);

  useFrame((state, delta) => {
    mixer.update(delta);
  });

  return (
    <group name="avatar">
      <primitive position={[0.05, 0, 0]} object={gltf.scene} dispose={null} />
    </group>
  );
}

export default Avatar;
